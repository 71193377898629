<template>
	<div class="app-body">
		<div class="a-flex-rsbc a-ptb-6">
			<span class="a-fs-16 a-fw-700">充电卡基本信息管理</span>
			<div class="a-flex-rcc">
				<le-export-btn :pageParam="pageParam"></le-export-btn>
				<el-dropdown class="a-ml-20" placement="bottom-start" @command="handlerAddCard">
					<el-button icon="el-icon-plus" type="primary" class="s-btn-add">卡片</el-button>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="a">XLS批量导入</el-dropdown-item>
						<el-dropdown-item command="c">XLS批量导入模板</el-dropdown-item>
						<el-dropdown-item command="b">新增卡片</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<el-card class="el-main">
			<le-search-form @reset="handlerRest" @search="handlerSearch" id="leSearch">
				<le-input label="卡号" v-model="pageParam.params.cardNo" placeholder="请输入卡号" />
				<le-date-range label="入库时间" :minDate.sync="pageParam.params.startTime"
					:maxDate.sync="pageParam.params.endTime" />
                <le-select-remote-search label="预设站点" v-model="pageParam.params.stationId" :options="optionsStation" placeholder="请选择（可输入搜索）" />
			</le-search-form>
			<le-pagview @setData="setTableData" :pageParam="pageParam" v-if="pageParam" :isFixed="true" :tableRef="$refs.chargeCardList">
				<el-table @filter-change='filterFun' ref="chargeCardList" :data="tableData" :highlight-current-row="true" style="width: 100%" v-sticky="{ top: 0, parent:'.el-card__body' }">
					<el-table-column prop="cardNo" label="卡号">
					</el-table-column>
					<el-table-column prop="description" label="类型">
                        <template slot-scope="{ row }">
                            <span>{{ row.cardType == 2?'电子卡':'实体卡' }}</span>
						</template>
					</el-table-column>
					<el-table-column 
                        prop="status" 
                        :key="22222"
                        current-row-key="2222"
                        column-key="status"
                        :filter-multiple='false'
                        :filters="statusDic"
                        label="是否停用">
						<template slot-scope="scope">
                            <el-switch
                                @change="changeCardStatus(scope.row,scope.$index)"
                                v-model="scope.row.status"
                                :inactive-value="0"
                                :active-value="1"
                                active-color="#F56C6C"
                                inactive-color="#409EFF">
                            </el-switch>
                            <span class="a-mlr-12" :class="scope.row.status?'a-c-error':'a-c-blue'">{{ scope.row.status?'已停用':'已启用' }}</span>
						</template>
					</el-table-column>
                    <el-table-column 
                        :key="1111"
                        current-row-key="11111"
                        column-key="reCharge"
                        :filter-multiple='false'
                        :filters="statusDic"
                        label="是否启用充值">
						<template slot-scope="scope">
                            <el-switch
                                @change="changeCardReCharge(scope.row,scope.$index)"
                                v-model="scope.row.reCharge"
                                :inactive-value="1"
                                :active-value="0"
                                active-color="#F56C6C"
                                inactive-color="#409EFF">
                            </el-switch>
                            <span class="a-mlr-12" :class="scope.row.reCharge?'a-c-blue':'a-c-error'">{{ scope.row.reCharge?'已启用':'已禁用' }}</span>
						</template>
					</el-table-column>
                    
                    <el-table-column label="预设站点">
						<template slot-scope="{ row }">
							<span>{{ row.stationName || '-' }}</span>
						</template>
					</el-table-column>
					<el-table-column label="入库时间">
						<template slot-scope="{ row }">
							<span>{{ util.formatDateDayjs(row.createTime, 'YYYY-MM-DD HH:mm:ss') }}</span>
						</template>
					</el-table-column>
				</el-table>
			</le-pagview>

		</el-card>
		<le-upload-file-img v-show="false" :uploadUrl="$Config.apiUrl.chargeCardBatchImport"
			:acceptArr="['.xls', '.xlsx']" notnull ref="pic" label="" @handleSuccess="importDevSuccess"
			v-model="deviceXLS"></le-upload-file-img>
		<el-dialog title="新增卡片" :visible.sync="dialogAddCard" width="30%" class="addDialog">
			<el-form :model="form">
				<el-form-item label="卡号" :label-width="'112px'">
					<el-input v-model="form.cardNo" autocomplete="off" placeholder="请输入卡号"></el-input>
				</el-form-item>
                <el-form-item label="是否启用充值" :label-width="'112px'">
                    <el-switch
                        v-model="form.reCharge"
                        :inactive-value="0"
                        :active-value="1"
                        active-color="#409EFF"
                        inactive-color="#F56C6C">
                    </el-switch>
                    <span class="a-mlr-12" :class="form.reCharge?'a-c-blue':'a-c-error'">{{ form.reCharge?'已启用':'已禁用' }}</span>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="resetForm">取消</el-button>
				<el-button type="primary" @click="handlerSubmit">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import util from '../../utils/index';
	import util2 from '../../utils/util.js'
	export default {
		data() {
			return {
				util: util,
				dialogAddCard: false,
				tableData: [],
				pageParam: null,
				deviceXLS: '',
				fileUrl: '', //文件导出url
				form: {
					cardNo: '',
                    reCharge: 1
				},
                statusDic: [],
                optionsStation: {
                    url: this.$Config.apiUrl.getStationInfoList,
                    method: "post",
                    params: {},
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "searchKey",
                },//站点数据
			}
		},
		methods: {
			handlerAddCard(command) {
				if (command == 'a') {
					// 批量导入
					this.$refs['pic'].$refs["upload"].$refs["upload-inner"].handleClick();
				} else if (command == 'b') {
					this.dialogAddCard = true
				} else if (command == 'c') {
					util2.download('https://file.hichar.cn/pc/NFC%E5%8D%A1%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx')
				}
			},
			resetForm() {
				this.dialogAddCard = false
				this.form.cardNo = ''
				this.form.reCharge = 1
				this.form.amount = ''
				return false
			},
			setTableData(data) {
				this.tableData = data;
			},
			importDevSuccess() {
				this.$message.success('导入成功')
				this.pageParam.freshCtrl++; // 重新请求表格数据
			},

			handlerRest() {
				this.pageParam.params = {
					exportFlag: false,
					cardNo: '',
					startTime: '',
					endTime: '',
                    stationId: '',
                    status: '',
                    reCharge: ''
				};
                this.pageParam.freshCtrl++;
			},
			handlerSearch() {
				this.pageParam.freshCtrl++;
			},
			handlerSubmit() {
				if (!this.form.cardNo.trim()) {
					this.dialogAddCard = false
					this.form.cardNo = ''
					return false
				}
				this.$Axios._get({
					url: this.$Config.apiUrl.chargeAddCard,
					params: {
						...this.form
					}
				}).then((res) => {
					if (res.result.code == 0) {
						this.$notify({
							title: res.result.data,
							type: 'success'
						})
						this.dialogAddCard = false
						this.form.cardNo = ''
						this.pageParam.freshCtrl++;
					} else {
						this.$message.error(res.result.message)
					}
				}).catch((error) => {
					this.$message.error(error);
				})
			},
            //条件筛选
            filterFun(value){
                for(var key in value){
                    if(key=='status'){
                        if(value[key].length==0){
                            this.pageParam.params.status = ''
                        }else{
                            this.pageParam.params.status = value[key][0]
                        }
                    }
                    if(key=='reCharge'){
                        if(value[key].length==0){
                            this.pageParam.params.reCharge = ''
                        }else{
                            this.pageParam.params.reCharge = value[key][0]
                        }
                    }
                }
                this.$refs['chargeCardList'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            changeCardReCharge (datas, index) {
                let txt
                if(datas.reCharge == 1){
                    txt = '是否确定启用该卡片？'
                }else{
                    txt = '是否确定禁用该卡片？'
                }
                this.$confirm(txt, '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.updateCard,
                        params: {
                            cardNo: datas.cardNo,
                            id: datas.id,
                            reCharge: datas.reCharge
                        }
                    }).then((res) => {
                        this.$message.success('操作成功')
                        this.pageParam.freshCtrl++;
                    }).catch((error) => {
                        this.$message.error(error);
                    })
                }).catch(_=>{
                    this.tableData[index].reCharge = this.tableData[index].reCharge == 0 ? 1 : 0
                })
                
            },
            changeCardStatus (datas, index) {
                let txt
                if(datas.status == 1){
                    txt = '是否确定停用该卡片？'
                }else{
                    txt = '是否确定启用该卡片？'
                }
                this.$confirm(txt, '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.updateCard,
                        params: {
                            cardNo: datas.cardNo,
                            id: datas.id,
                            status: datas.status
                        }
                    }).then((res) => {
                        this.$message.success('操作成功')
                        this.pageParam.freshCtrl++;
                    }).catch((error) => {
                        this.$message.error(error);
                    })
                }).catch(_=>{
                    this.tableData[index].status = this.tableData[index].status == 0 ? 1 : 0
                })
            }

		},
		created() {
            this.$getDic('yesOrNo').then(res=>{ this.statusDic = res; })
			this.pageParam = {
				url: this.$Config.apiUrl.chargeCardList,
				method: "post",
				params: {
					exportFlag: false,
					cardNo: '',
					startTime: '',
					endTime: '',
                    stationId: '',
                    status: '',
                    reCharge: ''
				},
				freshCtrl: 1,
			};
		},
	}
</script>
<style lang="scss" scoped>
	/deep/ .s-search-label {
	}

	.addDialog /deep/ .el-dialog__header {
		font-size: 16px;
		color: #303133;
		font-weight: bold;
		padding: 16px 24px;

	}

	.addDialog /deep/ .el-dialog__body {
		border-top: 1px solid #EBF0F5;
		border-bottom: 1px solid #EBF0F5;
		height: 136px;
		padding-left: 24px;
		padding-right: 24px;
	}

	.addDialog /deep/ .el-dialog__footer {
		padding-left: 24px;
		padding-right: 24px;
	}

	.addDialog /deep/ .el-form-item__label {
		text-align: left;

	}
	/deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
</style>
